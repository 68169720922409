@import './src/utils/Variables.scss';

.create-message-suggest {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $commentColor;
}

.text-input-area {
  height: 200px;
}

.create-message-step-email-container {
  .customer-editor-bar-container {
    max-width: 100%;
  }
}

.content-tips {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-inline-start: 12px;
  gap: 10px;
}

.tox {
  .tox-toolbar-overlord {
    .tox-toolbar:first-child {
      height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 8px;

      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 8px;

      &:not(.tox-toolbar--scrolling) {
        background-position: center top 1px;
        background-size: calc(100% - 11px * 2) calc(100% - 20px);
        background: none;
      }
      > div {
        padding-left: 0;
        padding-right: 0;
        &:last-child {
          padding-right: 11px;
        }
        button.tox-tbtn {
          margin: 0;
          height: 32px;
          padding: 0 12px;
          box-shadow: none;
          border-radius: 4px;
          background: #e8f1f8;

          cursor: pointer;

          color: #222f3e;
          font-family: Mulish;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;

          > span {
            cursor: pointer;
          }
          > div {
            margin-left: 16px;
          }
        }
        &:first-child {
          button.tox-tbtn {
            color: #086be6;
          }
        }
      }
    }
  }

  .tox-menu {
    max-width: 350px;
  }
}
