.customer-detail-edit-title-with-button {
  display: flex;
  margin-top: 32px;
  align-items: center;
}

.customer-detail-section-title-container {
  margin-top: 0;
}

.customer-detail-edit-title {
  margin-bottom: 0;
}

.customer-detail-section-title {
  font-family: MuliBlack;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  color: #002148;
}

.customer-detail-edit-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
}

.customer-detail-edit-button {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 8px 16px;
  height: auto;
}

.customer-detail-edit-button-second {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-left: 16px;
  margin-right: 0;
  padding: 8px 16px;
  height: auto;
}

.customer-detail-edit-image {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.customer-detail-button-label {
  margin-bottom: 0;
  cursor: pointer;
}

.customer-detail-status {
  margin-bottom: 0;
}

.customer-detail-level {
  margin-left: 8px;
  margin-bottom: 0;
  background: #eef1fb;
  color: #4e8dd7;
}

.customer-detail-tips-container {
  margin-top: 16px;
}

.customer-detail-tips {
  margin-left: 8px !important;
  margin-top: -30px !important;
  max-width: 700px !important;
}

.customer-detail-description {
  margin-top: 16px;
  margin-bottom: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8c8c8c;
}

.customer-detail-info-container {
  margin-top: 16px;
}

.customer-detail-info-title {
  margin-bottom: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4d5560;
}

.customer-detail-info-title-block {
  display: block;
}

.customer-detail-info-content {
  margin-left: 8px;
  margin-bottom: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.customer-detail-info-content-block {
  display: block;
  margin-left: 0;
  margin-top: 4px;
}

.customer-detail-show-more-button {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 0;
  margin-right: auto;
  padding: 8px 16px;
  height: auto;
}

.customer-detail-separate {
  margin-top: 30px;
  margin-bottom: 14px;
  width: '100%';
  height: 1px;
  background-color: #dadada;
}

.customer-detail-points-container {
  display: flex;
  margin-top: 16px;
  &:last-child {
    margin-right: 0;
  }
  &.gray-box {
    .customer-detail-point-status-container {
      background-color: #eeeeee;
    }
    .customer-detail-top-item-list-container {
      width: 33%;
    }
  }
  .loading-area {
    height: 400px;
  }
}

.customer-detail-point-status-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 24px;
  padding: 16px;
  border-radius: 8px;
  background-color: #eef1fb;
}

.customer-detail-top-item-list-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 24px;
  .customer-detail-point-status-value {
    margin-bottom: 0;
    padding: 16px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: #eeeeee;
  }
  .customer-detail-item-list-content {
    margin-bottom: 0;
    width: 100%;
    height: 213px;
    padding-right: 16px;
    padding-left: 16px;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-right-width: 1px;
    border-left-width: 1px;
    border-style: solid;
    border-color: #dadada;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    .table {
      width: 100%;
      table-layout: fixed;
      margin-bottom: 0;
      tr {
        height: 50px;
      }
    }
    .table th,
    .table td {
      padding: 15px 0;
      border-top: 0 solid transparent;
    }
    .table tr th:first-child {
      width: 90px;
    }
    .table tbody {
      td {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      th,
      td {
        font-family: Muli;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #404040;
      }
    }
    .table > thead {
      th {
        vertical-align: bottom;
        border-bottom: 2px solid #dadada;
      }
      th,
      td {
        font-family: Muli;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #979797;
      }
    }
  }
}

.customer-detail-point-status-value {
  margin-bottom: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4e8dd7;
}

.customer-detail-point-status-content {
  margin-top: 8px;
  margin-bottom: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.customer-detail-tag-container {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}

.customer-detail-single-tag {
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #eeeeee;
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4d5560;
}

.customer-detail-single-remark-container {
  margin-top: 16px;
  width: 100%;
}

.customer-detail-single-remark-title {
  display: flex;
  align-items: center;
}

.customer-detail-single-remark-admin-name {
  margin-bottom: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4d5560;
}

.customer-detail-single-remark-creation-date {
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
}

.customer-detail-delete-remark-image {
  width: 27px;
  height: 27px;
  margin-left: 16px;
  margin-right: 0;
  cursor: pointer;
}

.customer-detail-single-remark-content {
  margin-top: 8px;
  padding: 12px;
  border-radius: 8px;
  background-color: #eeeeee;
}

.customer-detail-single-remark-remark {
  margin-bottom: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
  white-space: pre-wrap;
}

.customer-detail-prompt {
  padding: 24px 30px;
  width: 800px;
}

.customer-detail-prompt-customer-group {
  padding: 24px 30px;
  width: 1064px;
}

.customer-detail-modal-title {
  margin-bottom: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
  color: #4d5560;
}

.customer-detail-reset-password-text {
  margin-top: 24px;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #4d5560;
}

.customer-detail-prompt-field-title {
  margin-top: 24px;
}

.customer-detail-prompt-buttons {
  margin-top: 24px;
}

.customer-detail-prompt-selector {
  width: 250px;
}
