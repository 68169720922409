.change-password-prompt-text {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #404040;
}

.base-prompt-container {
  .otp-code-container {
    width: 100%;
  }

  .change-password-comfirm-btn {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    margin-top: 20px;
  }

  .change-password-cancel-btn {
    font-family: Muli;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    margin-right: 0;
    align-self: center;
    background-color: inherit;
    border: none;
    color: #4e8dd7;
    width: auto;
    padding: 0;
    height: auto;
  }
}
