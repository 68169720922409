@import '../../utils/Variables.scss';

.rfm-definitions-image {
  width: 838px;
  height: 627px;
  margin-top: 40px;
  align-self: center;
}

.rfm-definitions-tips {
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
