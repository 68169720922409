.feature-campaign-detail-image {
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
  object-position:left;
}

.banners-detail-text {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #404040;
  margin-bottom: 0px;
  word-break: break-word;
}
