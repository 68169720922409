@import '../../utils/Variables.scss';

.side-menu-nav-area {
  padding-bottom: 20px;
}

.side-menu-nav-title {
  margin-left: 40px;
  height: 40px;
  font-family: MuliExtraBold;
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  margin-bottom: 0px;
  color: white;
  &.side-menu-nav-title-link {
    display: block;
    margin: 0;
    text-align: left;
    padding-left: 40px;
  }
}

.side-menu-nav-section {
  background-color: transparent;
  border: none;
}

.side-menu-nav-header {
  min-height: 40px;
  width: 100%;
  margin: 0px 0px;
  padding: 0px 0px;
  background-color: transparent;
  border: none;
}

.side-menu-nav-header-focus {
  background-color: $sideNavHoverColor;
  text-decoration: none;
  color: white;
}

.side-menu-nav-header a:hover {
  background-color: $sideNavHoverColor;
  text-decoration: none;
  color: white;
}

.side-menu-nav-item {
  min-height: 40px;
  // line-height: 40px;
  text-align: left;
  display: block;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: white;
  display: flex;
  flex: none;
  align-items: center;
  order: 1;
  margin: 0px 0px;
  width: 100%;
}
.side-menu-nav-item-content {
  line-height: 25px;
}

.side-menu-nav-item-second {
  padding-left: 55px;
}

.side-menu-nav-item-third {
  padding-left: 70px;
}
