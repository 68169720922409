.login {
  flex-direction: row;
  display: flex;
  // width: 100vw;
  // height: 100vh;
  position: relative;
  justify-content: space-between;

  .left-bg {
    position: absolute;
    height: 100vh;
    min-height: 800px;
    object-fit: contain;
    justify-content: flex-start;
  }

  .left-icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .reset-password-header {
    font-family: MuliExtraBold;
    font-size: 18px;
    font-weight: 900;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #002148;
    margin-bottom: 32px;
  }

  .login-form {
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    // min-width: 640px;
    // width: 50vw;
    height: 100vh;
    min-height: 800px;
    align-items: center;
    justify-content: center;
    padding-top: calc(1024 / 720);
  }

  .login-form-logo {
    width: 250px;
    height: 90px;
    margin-bottom: 64px;
  }
  .login-form-item {
    width: 325px;
    align-self: center;
    justify-content: flex-start;
    margin-bottom: 45px;
  }

  .login-form-label {
    width: 325px;
    height: 20px;
    font-family: MuliExtraBold;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
    margin-bottom: 8px;
  }
  .login-form-input {
    width: 325px;
    height: 40px;
    background: white;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding: 7px 8px;
  }

  .login-error {
    margin-top: 3px;
    height: 20px;
    color: red;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    margin-bottom: 0px;
  }

  .login-error-previous {
    color: transparent;
  }

  .login-form-input:active,
  .login-form-input:focus {
    border: 1px solid #2b84ee;
    outline: 0px;
    box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
  }

  .login-form-button {
    width: 325px;
    height: 40px;
    border-radius: 4px;
    margin-top: 20px;
  }

  .btn-link {
    font-family: Muli;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    margin-right: 0;
    align-self: center;
    background-color: inherit;
    border: none;
    color: #4e8dd7;
    width: auto;
    height: auto;
    padding: 0;
  }
}
.verify-channel-container {
  width: 325px;
  align-self: center;
  justify-content: flex-start;
  margin-bottom: 45px;
  .verify-channel-title {
    margin-top: 0;
  }
}

.otp-code-container {
  display: flex;
  flex-direction: column;
  width: 325px;
  align-self: center;
  justify-content: flex-start;
  .otp-code-title {
    margin-top: 0;
  }
  .opt-code-input {
    width: 55%;
  }
  .otp-code-send-button {
    width: 35%;
    border-radius: 4px;
    margin-left: 10%;
    padding: 0;
  }
}
