@import './src/utils/Variables.scss';

.dropdown-for-group-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: $titleColor;
  margin-right: 17px;
  margin-bottom: 0px;
  align-self: center;
}
.filterable-toogle-area-special {
  border-radius: 4px;
}

.dropdown-for-group-container {
  width: 300px;
  height: 40px;
  background: #ffffff;
  margin-right: auto;
}

.dropdown-for-item-toggle {
  width: 40px;
  height: 40px;
  object-fit: contain;
  align-self: center;
}

.dropdown-for-item-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 40px;
  text-transform: uppercase;
  color: $fieldColor;
  margin-bottom: 0px;
}

.dropdown-for-item-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

.dropdown-for-item-menu {
  background: #ffffff;
  border: 1px solid $lightGrey;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.5);
  border-radius: 8px;
  padding: 10px 20px;
  width: 200px;
  // position: fixed !important;
}

.error-field .filterable-toogle-area {
  border-color: $errorRed;
}

.dropdown-section {
  &.insert-param {
    width: 400px;
    margin-right: 5px;
    margin-left: 5px;
    &.field-has-error .filterable-toogle-area {
      border: 1px solid #ff0000;
    }
  }
  .insert-param-custom-input {
    margin: 0;
    flex: 1;
    width: auto;
    height: auto;
    border: initial;

    color: $fieldColor;
    font-family: Mulish;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;

    &:focus-visible {
      outline: initial;
    }
  }
  .filterable-dropdown-delete-selected {
    position: initial;
    margin-top: 4px;
    margin-bottom: 4px;
    width: 20px;
    height: 20px;
    object-fit: contain;
    align-self: center;
  }
  .divider {
    height: 20px;
    width: 1px;
    margin: 4px;
    background-color: $lightGreyTwo;
  }
  .btn-link-text-label {
    width: auto;
  }
  .filterable-toogle-area-indicator {
    margin-left: 8px;
  }
}
