@import '../../utils/Variables.scss';

.transaction-amount-total-value-label {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #404042;
  margin-right: 9px;
  margin-bottom: 0px;
}

.multiple-discounts {
  margin-top: 30px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 1px dashed #c2c2c2;
  border-radius: 4px;
  max-width: 502px;
}
.single-discounts {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .create-section-label {
    margin-bottom: 0;
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .title-and-delete {
    max-width: 370px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.single-additional-data-section {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .d-flex {
    align-items: center;
    gap: 9px;
    > label {
      font-family: Mulish;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      min-width: 42px;
    }
  }
  .title-and-delete {
    max-width: 450px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .text-input-field {
    max-width: 400px;
  }
}
.delete-img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
