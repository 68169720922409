.onboarding-slide-3 {
  display: flex;
  flex-direction: column;
  .text-container {
    display: flex;
    flex-direction: column;
    margin-top: 54px;
    width: 100%;
    padding: 0px 35px;
    align-self: center;
  }

  .content-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 44px;
    color: #000000;
  }
  .content-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 44px;
    color: #000000;
  }

  .button-area {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    z-index: 10;
    width: 100%;
  }

  .button-area-long {
    margin-top: 53px;
    height: 277px;
  }
  .button-area-middle {
    margin-top: 91px;
  }

  .button-item {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 237px;
  }

  .button-item-container {
    display: flex;
    flex-direction: column;
    max-width: 253px;
    width: 23%;
    height: auto;
  }
  .button-item-container:hover {
    cursor: pointer;
  }

  .button-icon {
    width: 100px;
    height: 98px;
    align-self: center;
  }

  .button-icon-long {
    margin-top: 42px;
  }

  .button-icon-short {
    margin-top: 46px;
  }

  .button-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    align-self: center;
    margin-top: 25px;
    width: 100%;
    padding: 0px 20px;
  }

  .button-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    margin-top: 12px;
  }
  .item-or-image-long {
    padding-bottom: 40px;
  }
  .item-or-image-middle {
    padding-bottom: 60px;
  }

  .slide-background-image {
    width: 100%;
    bottom: 0px;
    align-self: center;
    position: absolute;
    left: 0px;
  }
}
