@import './src/utils/Variables.scss';

#my-superset-container {
  height: 100vh;
  background: #ffffff;
  padding-top: 60px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 10px;
  width: 100%;
  position: relative;
  display: inline;
  @media screen and (max-width: $hideSideMenuWidth) {
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}
