.customer-detail-membership-section-label {
    margin-bottom: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
}

.customer-detail-membership-section-ul {
    padding-inline-start: 25px;
    font-family: Muli;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
}