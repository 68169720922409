.check-list-section {
  margin-top: 30px;
}
.check-list-item-container {
  margin: 8px 0 4px 0;
}
.checkbox-container.check-list-label {
  margin: 0 40px 0 0;
  color: #2b84ee;
  display: inline-block;
}
.checkmark.error-field {
  border-radius: 2px;
}
