@import '../../utils/Variables.scss';

.purchase-item-input-fields {
  padding-left: 12px;
  width: 200px;

  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
}

.item-addtional-data {
  margin-top: 30px;
  padding: 0 24px 16px;
  border: 1px dashed #c2c2c2;
  border-radius: 4px;
  max-width: 502px;
  .single-additional-data-section .text-input-field {
    width: 100%;
  }
}
