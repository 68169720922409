@import './src/utils/Variables.scss';

.custom-step-content {
  flex-direction: row;
  display: flex;
  justify-content: center;
  width: auto;
  align-items: center;
  margin-top: 30px;
  height: 70px;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
}
.no-step-content {
  height: 0;
  margin-top: 0;
}

.custom-one-step-content {
  display: flex;
  flex: 1;
  position: relative;
  overflow: visible;
}
.custom-last-step-content {
  flex: 0;
}

.edit-step-background {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  align-self: center;
}

.error-background {
  background-color: $errorRed;
}

.current-and-finished-background {
  background-color: $navyBlue;
}

.un-edited-background {
  background-color: white;
  border: 3px solid;
  border-color: $lightGrey;
  box-sizing: border-box;
}

.finished-and-error-step-icon {
  object-fit: contain;
  width: 15px;
  height: 15px;
}

.starting-step-icon {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: white;
  margin-bottom: 0px;
}

.step-line {
  background: $lightGrey;
  height: 3px;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
  align-self: center;
}

.waiting-step-icon {
  align-self: center;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: $deepGrey;
  margin-bottom: 0px;
}

.step-description-custom {
  display: block;
  margin-top: 10px;
  font-family: MuliExtraBold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 0px;
  overflow: visible;
  position: absolute;
  top: 40px;
  width: 125px;
  left: 50%;
  transform: translateX(-50%);
}

.step-description-custom-inactive {
  color: $deepGrey;
}

.step-description-custom-actived {
  color: $inkBlack;
}

.step-icon-container-long {
  margin-right: 216px;
}

.step-icon-container-short {
  margin-right: 183px;
}

.step-icon-container-last {
  margin-right: 0px;
}

.step-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  position: relative;
}
