.score-setting-container {
  width: 850px;
  height: 600px;
  overflow-y: scroll;
}

.recency-frequency-monetary-score-setting-separate {
  margin-top: 24px;
  width: 'auto';
  height: 1px;
  background-color: #e0e0e0;
}

.recency-frequency-monetary-score-setting-heading {
  margin-top: 24px;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  color: #4d5560;
}

.recency-frequency-monetary-score-setting-description {
  margin-top: 8px;
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.recency-frequency-monetary-score-setting-title {
  margin-top: 20px;
}

.recency-frequency-monetary-score-setting-drop-down {
  width: 120px;
}

.recency-frequency-monetary-score-setting-drop-down-image {
  margin-left: 0;
}

.recency-frequency-monetary-score-setting-error-message {
  display: block;
  margin-bottom: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ff0000;
}
