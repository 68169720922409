.rfm-dashboard-chart {
  margin: 0 auto;
}

.rfm-dashboard-chart-percentage {
  font: bold 16px Muli;
}

.rfm-pie-percentage {
  font: bold 18px Muli;
}

.rfm-pie-item-name {
  font: normal 16px Muli;
}

.rfm-pie-memberlist-container {
  flex-wrap: wrap;
  display: flex;
}

.rfm-pie-memberlist-container-item {
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  height: 40px;
  align-items: center;
}

.rfm-pie-memberlist-container-item-color-label {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.rfm-pie-memberlist-container-item-label {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
