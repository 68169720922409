@import '../../../utils/Variables.scss';
.preview-col-section {
  max-width: 21%;
  > div {
    font-family: Muli;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $darkBlue;
    margin-bottom: 8px;
  }
}
.preview-img {
  width: 100%;
}
div.coupon-type-area {
  justify-content: space-evenly;
}
.redemption-method-area {
  display: flex;
  margin-top: 8px;
  .radio-button-container {
    flex: 1;
    align-items: flex-start;
  }
}
