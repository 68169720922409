.create-section-inline-editor {
  margin-top: 8px;

  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  font-family: Mulish;
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  color: #404040;
  &.header {
    font-weight: 900;
    font-family: MuliBlack;
  }
  &.body {
    font-weight: 800;
    font-family: MuliExtraBold;
  }
  &.footer {
    font-weight: 400;
    color: #8a8a8a;
  }
  .next-line {
    width: 100%;
    height: 1px;
  }
}
