.add-new-reward-button {
  width: 40px;
  height: 40px;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
  border: 0px;
}
.add-new-reward-img {
  width: 40px;
  height: 40px;
}

.reward-item-container {
  box-sizing: border-box;
  border: 1px dashed #c2c2c2;
  border-radius: 10px;
  margin: 12px 0;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}
.margin-botton-section {
  margin-bottom: 24px;
}
.create-section-label-top-space {
  margin-top: 30px;
}
.custom-delete-btn {
  width: 24px;
  height: 24px;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
  outline: none !important;
  border: 0px;
}
.custom-delete-btn-container {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
