.day-input-fields {
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 12px;
  margin-right: 10px;
  height: 40px;
  width: 100px;

  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404040;

  &:focus,
  &:active {
    box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
    border: 1px solid #2b84ee;
    outline: 0px;
  }
}
.days-label {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #404042;
}

.no-margin {
  margin: 0;
}
