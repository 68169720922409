.container-common {
  padding-top: 60px;
}

.common-full-height {
  height: 100vh !important;
}

.title-in-block {
  position: absolute;
  margin-top: -20px;
  height: 40px;
  width: auto;
  padding: 0 16px;
  gap: 16px;
  border-radius: 8px;
  background-color: $greyG6;

  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  .create-section-title {
    width: auto;

    text-transform: initial;
    font-family: MuliBlack;
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
    text-align: center;
  }
  .btn-link.reset-button {
    margin: 0;
    width: auto;

    font-family: MuliExtraBold;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    text-align: center;
    color: $navyBlue;
  }
}

.title-error-area {
  padding-top: 20px;
}

.and-condition {
  padding-bottom: 20px;
}

.create-section-title {
  font-family: MuliBlack;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $darkBlue;
  margin: 0px;
}

.error-field,
.error-field:focus,
.error-field:active {
  border-color: #ff0000 !important;
  box-shadow: none !important;
}

.create-section-label {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: $titleColor;
}

.create-section-split-line {
  width: 100%;
  margin-top: 30px;
  height: 0;
  border-top: 1px solid #c2c2c2;
  &.dashed-border {
    border-top: 1px dashed #c2c2c2;
  }
}

.create-section-field-input {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $fieldColor;
}

.create-section-label-bottom-space {
  margin-bottom: 8px;
  margin-top: 30px;
}

.create-section-label-no-top-space {
  margin-top: 0px;
}

.create-section-label-top-space {
  margin-top: 30px;
}

.section-switch-button-description {
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: $deepGrey;
}

.section-short-description {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  color: $fieldColor;
}

.section-caution-description {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #979797;
  margin-top: 8px;
}

.dropdown-menu {
  z-index: 1;
}

.clickable-field:hover {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
