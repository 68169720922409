@import './src/utils/Variables.scss';

.customer-detail-prompt {
  padding: 24px 30px;
  width: 800px;
  &.campaign-select {
    .filterable-search-bar {
      margin-top: 16px;
      .filterable-search-bar-icon {
        left: 40px;
      }
    }
    .campaign-list-area {
      margin-right: -20px;
      max-height: 240px;
      overflow-y: overlay;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: $lightGreyTwo;
        border-radius: 2px;
      }
    }
  }
}
