.store-create-step-one {
  .dropdown-input-long {
    width: 355px;
  }
  .dropdown-short {
    width: 180px;
  }
  .qrcode-tips {
    display: block;
  }
}
.online-store-tip-popover {
  & ul {
    padding-left: 22px;
    list-style-type: disc;
  }
}
