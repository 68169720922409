@import './src/utils/Variables.scss';

.create-message-suggest {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $commentColor;
}

.text-input-area {
  height: 200px;
}

.message-general-section-whatsapp {
  .create-section-with-editor-bar {
    max-width: 100%;
    .title-suggest {
      max-width: 700px;
    }
    .editor-bar {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .customer-editor-bar-container {
        max-width: 100%;
      }
    }
  }

  .whatsapp-template-period {
    label.create-section-title {
      margin-top: 30px;
      font-family: Muli;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: $lightBlack;
      text-transform: initial;
    }
    div.collapse {
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        label.create-section-label-bottom-space {
          padding-bottom: 10px;
          width: 120px;
          margin: 0;
        }
      }
    }
  }
}
