.generate-coupon-modal {
  background: rgba(0, 0, 0, 0.4);

  .modal-content {
    width: 800px;
    padding: 20px 20px 40px 40px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }

  .modal-header {
    border: 0px;
    padding: 0px;

    .modal-close-btn {
      margin-left: auto;
      border: 0px;
      width: 32px;
      height: 32px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
      z-index: 1;

      &:focus,
      &:active {
        outline: 0px;
      }
    }
  }

  .modal-body {
    padding: 0px;
    margin-top: -16px;
    padding-right: 20px;

    .generate-coupon-title-section {
      .second-section-content {
        line-height: 24px;
        margin-top: 6px;
      }
    }

    .second-section-content {
      line-height: 36px;
    }
  }

  .modal-footer {
    padding: 0px;
    border: 0px;
    padding-right: 20px;

    .generate-coupon-btn {
      border-radius: 100px;
      height: 44px;
      margin-top: 30px;
    }
  }
}

.generate-modal {
  .base-prompt-other-body {
    .second-section-content {
      line-height: 24px;
    }
  }
  .import-generate-code-scrollable-container {
    margin-top: 12px;
    border: 1px solid #c2c2c2;
    border-radius: 8px;
    padding: 12px 16px;
    overflow-y: scroll;
    max-height: 312px;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #c2c2c2;
      border-radius: 2px;
    }

    .generate-coupon-second-title {
      font-weight: 700;
      line-height: 24px;
    }
    .generate-coupon-second-tips {
      font-weight: 400;
      font-size: 16px;
      margin-top: 8px;
    }
    .generate-coupon-store {
      margin-top: 8px;
      justify-content: space-between;
      align-items: center;
      .day-input-fields {
        margin-right: 0;
      }
    }
    .second-section-content {
      margin-top: 8px;
    }
  }
  .link-button-text {
    height: 32px;
    padding: 0 16px;
    border-radius: 100px;
    border: 1.5px solid #4e8dd7;

    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-decoration-line: none;

    &:not(.uploaded-file-button):hover,
    &:not(.uploaded-file-button):active,
    &:not(.uploaded-file-button):focus {
      height: 32px;
      padding: 0 16px;
      border-radius: 100px;
      border: 1.5px solid #4e8dd7;

      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-decoration-line: none;
    }
    &.uploaded-file-button {
      border-radius: 8px;
      border: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      cursor: auto;
    }
  }
}

.link-button-text {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  color: #4e8dd7;

  padding: 0;

  border: none;
  background: transparent;
  border-color: transparent;
  outline: none !important;
  width: fit-content;
}

.link-button-text:visited,
.link-button-text:hover,
.link-button-text:active,
.link-button-text:focus {
  border: none;
  background: transparent;
  border-color: transparent;
  outline: none !important;
  width: fit-content;

  color: #4e8dd7;
  text-decoration-line: underline;
}
