@import '../../utils/Variables.scss';
.custom-switch-button {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.custom-switch-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch-button-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $lightGrey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 28px;
}

.custom-switch-button-slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .custom-switch-button-slider {
  background-color: $navyBlue;
}

input:checked + .custom-switch-button-slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

input:disabled + .custom-switch-button-slider:before{
  opacity: 0.6;
}

.custom-switch-button-slider.round {
  border-radius: 28px;
}

.custom-switch-button-slider.round:before {
  border-radius: 50%;
}
