.variant-title {
  margin-top: 20px;
  font-family: Muli;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  color: #002148;
}

.content-area.gift-card-config-variant {
  margin-top: 12px;

  .display-flex-area {
    display: flex;
    position: relative;
    flex-direction: column;
  }

  .reset-button,
  .reset-button:visited,
  .reset-button:hover,
  .reset-button:active,
  .reset-button:focus {
    border: none;
    background: transparent;
    border-color: transparent;
    outline: none !important;
  }

  .reset-button {
    margin-left: 20px;
  }

  .content-delete-button-area {
    margin-left: auto;
    margin-top: 30px;

    width: 27px;
    height: 27px;
    position: absolute;
    right: 0;
    .content-delete-button {
      width: 27px;
      height: 27px;
    }
  }
}
