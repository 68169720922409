.number-range-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 260px;
  height: 40px;
  padding: 0 11px;

  border: 1px solid #c2c2c2;
  border-radius: 4px;

  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  > input {
    border: none;
    max-width: 100px;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #8c8c8c;
    }
  }
}

.number-range-input-section-spacing {
  margin-top: 30px;
}
