.custom-date-time-select-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .dropdown {
    margin-bottom: 10px;
  }
  .select-time-text {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #404040;
    text-align: left;
    padding: 0px;
    padding-left: 12px;
  }

  .custom-date-select-container {
    background: white;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 4px;
    width: 210px;
    height: 40px;
    padding: 8px 12px;
    display: flex;
    line-height: 20px;
    justify-content: space-between;
  }

  .custom-date-select-container.custom-date-select-container-empty {
    justify-content: flex-end;
  }

  .custom-date-select-icon {
    width: 16px;
    height: 16px;
    align-self: center;
  }

  .react-datepicker-wrapper {
    margin-right: 17px;
  }

  .custom-date-time-select-reset-button {
    width: 27px;
    height: 27px;
    align-self: center;
  }

  .custom-seconds-select {
    margin-right: 17px;
  }

  .custom-hour-minutes-select:focus {
    box-shadow: none;
    border-color: #c2c2c2;
  }

  .custom-hour-minutes-select-dropdown-menu {
    max-height: 200px;
    overflow-x: auto;
    border: 1px solid #c2c2c2;
  }

  .custom-hour-minutes-control {
    padding: 8px 12px;
    background: #ffffff;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
  }
  .custom-date-select-container:disabled {
    opacity: 0.6;
  }

  .custom-hour-minutes-control:focus,
  .custom-hour-minutes-control:hover,
  .custom-hour-minutes-control:not(:disabled):not(.disabled):active,
  .custom-hour-minutes-control:not(:disabled):not(.disabled):active:focus,
  .select-time-text:disabled {
    box-shadow: none;
    background: #ffffff;
    text-decoration: none;
    color: #404040;
  }

  .dropdown-menu-sizing {
    min-width: 120px;
    height: 40px;
    line-height: 20px;
  }

  .time-select-dropdown-menu {
    width: 100%;
    min-width: 0;
  }

  .custom-hour-minutes-control::after {
    display: none;
  }

  .custom-hour-minutes-splite {
    height: 40px;
    width: 17px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
