@import '../../../utils/Variables.scss';

.create-campaign-brand-select {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.general-section-add-new-brand {
  padding-bottom: 3px;
  margin-top: 3px;
  // align-self: center;
}

.general-section-add-new {
  padding-bottom: 3px;
  margin-top: 12px;
  // align-self: center;
}

.align-left {
  margin-left: 0;
  margin-right: auto;
}

// .v2-selector-multiple-transfer > .ant-transfer-list > div.ant-transfer-list-header > span.ant-transfer-list-header-title,
// .v2-selector-multiple-transfer > .ant-transfer-list > div.ant-transfer-list-header > span.ant-transfer-list-header-selected {
//   top: -25px;
// }
// .v2-selector-multiple-transfer > .ant-transfer-list > .ant-transfer-list-body > .ant-transfer-list-body-search-wrapper {
//   top: 2px
// }
