@import './ColorVariables.scss';
.btn-link .btn-link:focus {
  padding: 0px;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: $linkBtnColor;
  align-self: center;
  box-shadow: none;
}

.btn-link:hover,
.btn-link:not(:disabled):not(.disabled):active {
  text-decoration: none;
  color: $linkBtnColor;
  // background-color: $linkBtnBackGroundActive;
}

.btn-link:disabled {
  color: $linkBtnDisabledColor;
  opacity: 0.6;
}

.btn-add-button-common,
.btn-add-button-common:focus {
  background-color: $secondaryBtnBackground;
  border: 2px solid;
  border-color: $secondaryBtnBorderColor;
  color: $secondaryBtnColor;
  font-family: Muli;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 34px;
  box-shadow: none;
  padding: 3px 20px;
  border-radius: 100px;
}

.btn-add-button-common:hover,
.btn-add-button-common:not(:disabled):not(.disabled):active {
  background-color: $secondaryBtnBackgroundActive;
  border-color: $secondaryBtnBorderColor;
  color: $secondaryBtnColor;
  box-shadow: none;
}

.btn-add-button-common:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-add-button-common:disabled {
  background-color: $secondaryBtnBackgroundDisabled;
  opacity: 0.6;
  border-color: $secondaryBtnBorderColor;
  color: $secondaryBtnColorDisabled;
}

.btn-back-button-common,
.btn-back-button-common:focus {
  background-color: $secondaryBtnBackground;
  border: 2px solid;
  border-color: $secondaryBtnBorderColor;
  color: $secondaryBtnColor;
  font-family: Muli;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  height: 40px;
  box-shadow: none;
  padding: 6px 25px;
  border-radius: 100px;

  width: fit-content;
}

.btn-back-button-common:hover,
.btn-back-button-common:not(:disabled):not(.disabled):active {
  background-color: $secondaryBtnBackgroundActive;
  border-color: $secondaryBtnBorderColor;
  color: $secondaryBtnColor;
  box-shadow: none;
}

.btn-back-button-common:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-back-button-common:disabled {
  background-color: $secondaryBtnBackgroundDisabled;
  opacity: 0.6;
  border-color: $secondaryBtnBorderColor;
  color: $secondaryBtnColorDisabled;
}


.btn-back-button-common .arrow-svg {
    > path {
      fill: $primaryBtnBackground;
      stroke: $primaryBtnBackground;
    }
}
.btn-further .arrow-svg {
  > path {
    fill: $primaryBtnColor;
    stroke: $primaryBtnColor;
  }
}

.btn-further,
.btn-further:focus {
  color: $primaryBtnColor;
  background-color: $primaryBtnBackground;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  box-shadow: none;
  padding: 7px 25px;
  height: 40px;
  border-radius: 100px;
}

.btn-further:hover,
.btn-further:not(:disabled):not(.disabled):active {
  background-color: $primaryBtnBackgroundActive;
  color: $primaryBtnColor;
  box-shadow: none;
}

.btn-further:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-further:disabled {
  background-color: $primaryBtnBackgroundDisabled;
  opacity: 0.6;
  box-shadow: none;
  outline: none;
  border: none;
  color: #d5e4f5;
}
