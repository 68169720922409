@import './src/utils/Variables.scss';

.message-channel-container {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;

  min-width: 200px;
  max-width: 300px;
  height: auto;
  max-height: 400px;

  padding: 20px;

  display: flex;
  flex-direction: column;

  background: white;
  border: 2px solid $lightGrey;
  box-sizing: border-box;
  border-radius: 10px;
  align-self: flex-start;

  &.selected {
    border: 2px solid $selectedBlue;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px $selectedBlueShadow;
  }
  &.disabled {
    opacity: 0.6;
    background-color: #EEEEEE;
  }

  .campaign-type-title {
    font-family: MuliExtraBold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $inkBlack;
    align-self: center;
    margin-bottom: 0px;
    // margin-top: 25px;
  }

  .campaign-type-image {
    margin-top: 20px;
    width: 260px;
    max-width: 100%;
    height: 130px;
    box-sizing: border-box;
    align-self: center;
    object-fit: contain;

    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .message-channel-description {
    margin-top: 40px;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: $inkBlack;
    align-self: center;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
  }

  .message-channel-suggest {
    text-align: center;
    color: $deepGrey;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  .channel-title-div {
    display: flex;
    justify-content: center;
  }
}
