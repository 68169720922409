@import './src/utils/Variables.scss';

.whatsapp-template-dropdown {
  .filterable-search-bar {
    display: none;
  }
  .filterable-dropdown-menu-select-area {
    overflow-y: overlay;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: $lightGreyTwo;
      border-radius: 2px;
    }
  }
}
