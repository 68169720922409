@import './src/utils/Variables.scss';

.create-message-suggest {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $commentColor;
}

.text-input-area {
  height: 200px;
}

.message-general-section-SMS {
  .create-section-with-editor-bar {
    max-width: 100%;
    .title-suggest {
      max-width: 700px;
    }
    .editor-bar {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .customer-editor-bar-container {
        max-width: 100%;
      }
    }
  }
}
