.test-account-buttons-div {
  .test-account-button {
    height: 44px;
  }
}

.success-prompt-btn,
.success-prompt-btn:focus {
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}
