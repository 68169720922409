@import '../../utils/Variables.scss';

.rfm-dashboard-section-container {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 28px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(154, 161, 171, 0.3) 0px 0px 10px;
  border-radius: 8px;
  min-height: 698px;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.rfm-dashboard-card-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
}
