@import './src/utils/Variables.scss';

.create-section-with-editor-bar {
  max-width: 700px;
  display: flex;
  flex-direction: row;
  &.multi-line {
    flex-direction: column;
  }
  .create-section-suggest {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $commentColor;
  }
  .title-suggest {
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .editor-bar {
    width: auto;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: end;
  }
}

.customer-editor-bar-container {
  margin-bottom: 8px;
  max-width: 700px;
  height: auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;

  &.short-screen {
    max-width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
  }
  > div {
    height: 40px;
    &.dropdown-section {
      margin-right: 0;
    }
  }
  .filterable-toogle-area {
    .filterable-dropdown-delete-selected {
      display: None;
    }
  }
}
