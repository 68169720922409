@import './src/utils/Variables.scss';

.customer-additional-data {
  width: 800px;
  .help-message {
    margin-top: 0;
    margin-bottom: 0;
    width: 60px;
  }
  .custom-markdown-area-title.custom-markdown-area-title-short {
    width: auto;
    flex: 1;
  }
  .custom-markdown-area-title-short {
    max-width: 690px;
  }
  .inline-field {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .custom-switch-button {
      margin-top: 8px;
    }
    .custom-select-container {
    }
  }
}
