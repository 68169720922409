.transaction-action-prompt {
  .deactive-item-value {
    position: absolute;
    left: 248px;
    margin-left: 0;
  }
  .second-section-content {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
}

.transaction-import-btn {
  margin-right: 10px;
}
