.customer-detail-table {
    margin-top: 30px;

    th, td {
        padding: 16px 24px;
        border: 1px solid #dadada;
        font-family: Muli;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #404040;
        white-space: pre-wrap;
    }

    .customer-detail-table-blod-font {
        font-family: Muli;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        color: #4D5560;
    }

    .customer-detail-table-clickable {
        cursor: pointer;
        color: #4F8DD7;
    }
}

