// common color
$navyBlue: #4e8dd7;
$selectedBlue: #2b84ee;
$selectedBlueShadow: rgba(43, 132, 238, 0.5);
$darkBlue: #002148;
$inkBlack: #404040;
$lightBlack: #4d5560;
$deepGrey: #8c8c8c;
$lightGrey: #dadada;
$lightGreyTwo: #c2c2c2;
$greyG6: #f3f3f4;
$tintsWhite: #fafbfe;
$lightBlue: #47cca7;
$errorRed: #ff0000;
$backgroundGrey: #fafbfe;
$sideNavHoverColor: #ffffff25;
$titleColor: #4d5560;
$fieldColor: #404040;
$primaryBtnBackground: #4e8dd7;
$primaryBtnBackgroundActive: #4b7bb5;
$primaryBtnBackgroundDisabled: #4e8dd7;
$primaryBtnColor: #ffffff;
$secondaryBtnBackground: #ffffff;
$secondaryBtnBackgroundActive: #e6f0fd;
$secondaryBtnBackgroundDisabled: #eeeeee;
$secondaryBtnBorderColor: #4e8dd7;
$secondaryBtnColor: #4e8dd7;
$secondaryBtnColorDisabled: #4e8dd7;
$linkBtnBackGroundActive: #e6f0fd;
$linkBtnColor: #4e8dd7;
$linkBtnDisabledColor: #4e8dd7;
$shadowColor: #98a2ac;
$commentColor: #979797;
