.uploaded-file-button,
.uploaded-file-button:disabled {
  background-color: #eeeeee;
  border-radius: 8px;
  color: #404040;
  opacity: 1;

  display: flex;
  align-items: center;
  gap: 8px;
  cursor: auto;

  &:visited,
  &:hover,
  &:active,
  &:focus {
    border: none;
    background: #eeeeee;
    color: #404040;
    outline: none;
    text-decoration-line: none;
  }

  > div {
    display: flex;
    align-items: center;
    height: 24px;
  }
}
.remove-file-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
